import { UserAuthService } from '.';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpService } from './http.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  lastLoggedIn: Date;
  accessed: boolean;
  constructor(private authenticationService: UserAuthService,
    private httpService: HttpService, private router: Router) {
    this.accessed = false;
    this.checkLoggedInTime();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.checkLoggedInTime();
    const userService = new UserAuthService(this.httpService, this.router);
    const currentUser = userService.currentUserValue;
    const isLoggedIn = currentUser && currentUser.token;
    let authReq: any;
    if (isLoggedIn) {
      authReq = req.clone({
        setHeaders: {
          'x-tyfa-token': `${currentUser.token}`,
        },
      });
    } else {
      const headers = req.headers;
      authReq = req.clone({ headers });
    }
    return next.handle(authReq).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          switch (err.status) {
            case 401:
              this.authenticationService.logout();
              break;
            case 403:
              this.authenticationService.systemLock();
              break;
          }
        }
        return throwError(err);
      }));

  }
  checkLoggedInTime() {
    if (localStorage.getItem('llt')) {
      const minutes = moment.duration(moment(new Date()).diff(new Date(parseFloat(localStorage.getItem('llt') as string)))).asMinutes();
      if (minutes > 120 && !this.accessed) {
        this.accessed = true;
        this.authenticationService.logout({ logged_out_minutes: minutes }).subscribe((loggedOut: any) => {
          localStorage.removeItem('itIsOow');
          localStorage.removeItem('llt');
          this.router.navigate(['/login']);
        }, (error: any) => {
        });
      } else {
        this.accessed = false;
        localStorage.setItem('llt', new Date().getTime().toString());
      }
    } else {
      localStorage.setItem('llt', new Date().getTime().toString());
    }
  }
}
