import { SystemConfiguration } from '../../models/system-configuration';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { DialogsService } from 'src/app/shared/services/dialogs.service';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  colors = ['#126385', '#1f82a5', '#2194b8', '#2ea6ca', '#38b5d7', '#4ec0da', '#67cbdd'];
  someMoreRandomColors = ['#8ddae5', '#b8e9ee', '#e3f6f8', '#275674', '#327696', '#3687a9', '#4099bb', '#56b3ce', '#6bc0d4', '#265673', '#1c5376'];
  constructor(private dialogsService: DialogsService, private titleService: Title) { }
  async toDataURL(url) {
    const result = await new Promise<string>((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result.toString());
        }
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    });
    return result;
  }
  createOrgParams(queryString: OrgQueryString) {
    let params = new HttpParams();

    if (queryString.areaId) {
      params = params.append('area_id', queryString.areaId.toString());
    }

    if (queryString.filter) {
      params = params.append('filter', queryString.filter);
    }

    if (queryString.sortOrder) {
      params = params.append('sortOrder', queryString.sortOrder);
    }

    if (queryString.pageNumber) {
      params = params.append('pageNumber', queryString.pageNumber.toString());
    }

    if (queryString.pageSize) {
      params = params.append('pageSize', queryString.pageSize.toString());
    }
    return params;
  }
  confirmToDelete(data: any, callback: Function) {
    this.dialogsService.confirm('Confirmation', data.message).subscribe((res) => {
      callback(res);
    }, (error) => {
      callback(false);
    });
  }
  confirmDialogToDelete(data: any, callback: Function) {
    this.dialogsService.delete_picture_confirm(data).subscribe((res) => {
      callback(res);
    }, (error) => {
      callback(false);
    });
  }

  calculateAgeBySystemConfiguration(systemConfig: SystemConfiguration, userDOB: any) {
    const dobd: string = userDOB ? userDOB.replace(/[\W_]/g, '') : null;
    if (dobd && dobd.length === 8) {
      const sysdate = moment(systemConfig.asOfDate, ['MM-DD-YYYY', 'YYYY-MM-DD']).date();
      const sysmonth = moment(systemConfig.asOfDate, ['MM-DD-YYYY', 'YYYY-MM-DD']).month();
      const sysyear = moment(systemConfig.asOfDate, ['MM-DD-YYYY', 'YYYY-MM-DD']).year();
      const dobDate = moment(userDOB, ['MM-DD-YYYY', 'YYYY-MM-DD']);
      const prtdate = moment(dobDate, ['MM-DD-YYYY', 'YYYY-MM-DD']).date();
      const prtmonth = moment(dobDate, ['MM-DD-YYYY', 'YYYY-MM-DD']).month();
      const prtyear = moment(dobDate, ['MM-DD-YYYY', 'YYYY-MM-DD']).year();
      const prtDob = moment([prtyear, prtmonth, prtdate]);
      const sysconfDate = moment([sysyear, sysmonth, sysdate]);
      return sysconfDate.diff(prtDob, 'year'); // 1}
    } else {
      return 0;
    }
  }
  calculateAgeforWarehouseBySystemConfiguration(systemConfig: SystemConfiguration, userDOB: any, userCreateDate: any) {
    const dobd: string = userDOB ? userDOB.replace(/[\W_]/g, '') : null;
    if (dobd && dobd.length === 8) {
      const sysdate = moment(systemConfig.asOfDate, ['MM-DD-YYYY', 'YYYY-MM-DD']).date();
      const sysmonth = moment(systemConfig.asOfDate, ['MM-DD-YYYY', 'YYYY-MM-DD']).month();
      const sysyear = moment(userCreateDate, ['MM-DD-YYYY', 'YYYY-MM-DD']).year();
      const dobDate = moment(userDOB, ['MM-DD-YYYY', 'YYYY-MM-DD']);
      const prtdate = moment(dobDate, ['MM-DD-YYYY', 'YYYY-MM-DD']).date();
      const prtmonth = moment(dobDate, ['MM-DD-YYYY', 'YYYY-MM-DD']).month();
      const prtyear = moment(dobDate, ['MM-DD-YYYY', 'YYYY-MM-DD']).year();
      const prtDob = moment([prtyear, prtmonth, prtdate]);
      const sysconfDate = moment([sysyear, sysmonth, sysdate]);
      return sysconfDate.diff(prtDob, 'year'); // 1}
    } else {
      return 0;
    }
  }
  setTitle(title?: string) {
    this.titleService.setTitle(title ? title : 'TYFA App');
  }

  getSysConfigYearSeason(sysconfig: SystemConfiguration) {
    const month = moment().month() + 1;
    const whereStatement: any = { season: null, year: null };
    const springPeriodFrom = new Date(sysconfig.springPeriodFrom as Date);
    const fallPeriodFrom = new Date(sysconfig.fallPeriodFrom as Date);
    if (sysconfig) {
      if (month === 1 || month === 2 || month === 3 || month === 4 || month === 5) {
        whereStatement.season = 'Spring';
        whereStatement.year = springPeriodFrom.getFullYear();
      } else if (month === 6 || month === 7 || month === 8 || month === 9 || month === 10 || month === 11 || month === 12) {
        whereStatement.season = 'Fall';
        whereStatement.year = fallPeriodFrom.getFullYear();
      }
    }
    return whereStatement;
  }

  calculateTimeDifference(systemConfig: SystemConfiguration) {
    const nowdate = moment(new Date(), ['MM-DD-YYYY', 'YYYY-MM-DD']).date();
    const nowmonth = moment(new Date(), ['MM-DD-YYYY', 'YYYY-MM-DD']).month();
    const nowyear = moment(new Date(), ['MM-DD-YYYY', 'YYYY-MM-DD']).year();
    const now = moment([nowyear, nowmonth, nowdate]);
    const duedate = moment(systemConfig.paymentDueDate, ['MM-DD-YYYY', 'YYYY-MM-DD']).date();
    const duemonth = moment(systemConfig.paymentDueDate, ['MM-DD-YYYY', 'YYYY-MM-DD']).month();
    const dueyear = moment(systemConfig.paymentDueDate, ['MM-DD-YYYY', 'YYYY-MM-DD']).year();
    const end = moment([dueyear, duemonth, duedate]); // another date
    return now.diff(end, 'hour');
  }
  calculateCrossoverDateDifference(systemConfig: SystemConfiguration) {
    const nowdate = moment(new Date(), ['MM-DD-YYYY', 'YYYY-MM-DD']).date();
    const nowmonth = moment(new Date(), ['MM-DD-YYYY', 'YYYY-MM-DD']).month();
    const nowyear = moment(new Date(), ['MM-DD-YYYY', 'YYYY-MM-DD']).year();
    const now = moment([nowyear, nowmonth, nowdate]);
    const duedate = moment(systemConfig.crossoverLateFeeDate, ['MM-DD-YYYY', 'YYYY-MM-DD']).date();
    const duemonth = moment(systemConfig.crossoverLateFeeDate, ['MM-DD-YYYY', 'YYYY-MM-DD']).month();
    const dueyear = moment(systemConfig.crossoverLateFeeDate, ['MM-DD-YYYY', 'YYYY-MM-DD']).year();
    const end = moment([dueyear, duemonth, duedate]); // another date
    return now.diff(end, 'hour');
  }
  isRosterCreationOrDeletionLocked(systemConfig: SystemConfiguration) {
    const currentDate = new Date();
    const cheerRosterLockDate = new Date(systemConfig.cheerRosterLockDate ? systemConfig.cheerRosterLockDate : '');
    if (systemConfig.cheerRosterLockDate && cheerRosterLockDate <= currentDate) {
      return true;
    } else {
      return false;
    }
  }
  showDateMonthOfAgeCalculation(systemConfig: SystemConfiguration) {
    if (systemConfig) {
      const date = moment(systemConfig.asOfDate).date();
      const month = moment(systemConfig.asOfDate).month() + 1;
      return month + '/' + date;
    } else {
      return null;
    }
  }
  randomString(value: any) {
    if (value.bracket.description.toLowerCase() === 'tykes') { return this.colors[0]; }
    if (value.bracket.description.toLowerCase() === 'rookies') { return this.colors[1]; }
    if (value.bracket.description.toLowerCase() === 'juniors') { return this.colors[2]; }
    if (value.bracket.description.toLowerCase() === 'mascots') { return this.colors[3]; }
    if (value.bracket.description.toLowerCase() === 'flag') { return this.colors[4]; }
    if (value.bracket.description.toLowerCase() === 'seniors') { return this.colors[5]; }
    if (value.bracket.description.toLowerCase() === 'varsity') {
      return this.colors[6];
    } else { return this.someMoreRandomColors[Math.floor(Math.random() * 10)]; }
  }

  createParams(queryString: QueryString) {
    let params = new HttpParams();
    if (queryString.searchText) {
      params = params.append('searchText', queryString.searchText.toString());
    }
    if (queryString.role) {
      params = params.append('role', queryString.role.toString());
    }
    return params;
  }
  getAthletesTransactionWithFee(athletesTransactions: any, systemConfiguration: any) {
    const afee = 0;
    athletesTransactions.map((row: any) => {
      const feeStructure = this.calculateAthleteFees(row.athlete.organization, systemConfiguration);
      if (row.athlete.sport && (row.athlete.sport.name === 'Football' || row.athlete.sport.name === 'Both')) {
        row.fee = afee + feeStructure.authFFee;
      } else if (row.athlete.sport && row.athlete.sport.name === 'Cheer') {
        row.fee = afee + feeStructure.authCFee;
      }
    });
    return athletesTransactions;
  }
  calculateAthleteFees(organization: any, systemConfiguration: any) {
    const month = moment().month() + 1;
    let authCFee = 0;
    let authFFee = 0;
    if (systemConfiguration) {
      if (month === 1 || month === 2 || month === 3 || month === 4 || month === 5) {
        if (organization.isNonTYFA) {
          authCFee = Number(systemConfiguration.springCheerNonTYFAFee);
          authFFee = Number(systemConfiguration.springPlayerNonTYFAFee);
        } else {
          authCFee = Number(systemConfiguration.springCheerFee);
          authFFee = Number(systemConfiguration.springPlayerFee);
        }

      } else if (month === 6 || month === 7 || month === 8 || month === 9 || month === 10 || month === 11 || month === 12) {
        if (organization.is_open_organization) {
          if (organization.isNonTYFA) {
            authCFee = Number(systemConfiguration.fallCheerNonTYFAFee);
            authFFee = Number(systemConfiguration.fallPlayerNonTYFAFee);
          } else {
            authCFee = Number(systemConfiguration.fallOpenCheerFee);
            authFFee = Number(systemConfiguration.fallOpenPlayerFee);
          }
        } else {
          if (organization.isNonTYFA) {
            authCFee = Number(systemConfiguration.fallCheerNonTYFAFee);
            authFFee = Number(systemConfiguration.fallPlayerNonTYFAFee);
          } else {
            authCFee = Number(systemConfiguration.fallCheerFee);
            authFFee = Number(systemConfiguration.fallPlayerFee);
          }
        }
      }
    } else {
      authCFee = 1;
      authFFee = 1;
    }
    return { authCFee: authCFee, authFFee: authFFee };
  }
  calculateAthleteTotalRefundedFees(athletesTransactions: any) {
    let refundedFee = 0;
    athletesTransactions.forEach((transaction: any) => {
      if (transaction.has_refunded) {
        refundedFee += transaction.fee;
      }
    });
    return refundedFee.toFixed(2);
  }
  calculateVolunteerFees(organization: any, sysconfig: any) {
    const month = moment().month() + 1;
    let voltFee: any;
    if (sysconfig) {
      if (month === 1 || month === 2 || month === 3 || month === 4 || month === 5) {
        if (organization.isNonTYFA) {
          voltFee = Number(sysconfig.springVolunteerNonTYFAFee);
        } else {
          voltFee = Number(sysconfig.springVolunteerFee);
        }

      } else if (month === 6 || month === 7 || month === 8 || month === 9 || month === 10 || month === 11 || month === 12) {
        if (organization.is_open_organization) {
          if (organization.isNonTYFA) {
            voltFee = Number(sysconfig.fallVolunteerNonTYFAFee);
          } else {
            voltFee = Number(sysconfig.fallOpenVolunteerFee);
          }
        } else {
          if (organization.isNonTYFA) {
            voltFee = Number(sysconfig.fallVolunteerNonTYFAFee);
          } else {
            voltFee = Number(sysconfig.fallVolunteerFee);
          }
        }
      }
    } else {
      voltFee = 1;
    }
    return { voltFee: voltFee };
  }
  calculateVolunteerTransactoinsWithFee(transactions: any, systemConfiguration: any) {
    transactions.forEach((volunteerTransaction: any) => {
      volunteerTransaction.fee = this.calculateVolunteerFees(volunteerTransaction.volunteer.organization, systemConfiguration).voltFee;
    });
    return transactions;
  }
  calculateVolunteerTotalRefundedFees(transactions: any) {
    let refundedFee = 0;
    transactions.forEach((transaction: any) => {
      if (transaction.has_refunded) {
        refundedFee += transaction.fee;
      }
    });
    return refundedFee.toFixed(2);
  }
  getDateTimeOffsetDateOnly(date: string) {
    return moment.utc(date, "YYYY-MM-DD").format('YYYY-MM-DD');
  }
  getmmddyyyyDateTimeOffsetDateOnly(date: string) {
    if (date) { return moment.utc(date, "YYYY-MM-DD").format('MM/DD/YYYY'); } else {
      return '';
    }

  }
  checkFileFormatIfImageOrPDF(fileNameWithExtension: string) {
    const fileExt = fileNameWithExtension.split('.').pop();
    const fileFormat = ['pdf', 'doc', 'docx', 'jpg', 'jpeg', 'png'];
    if (fileExt && fileFormat.find(ex => ex === fileExt)) {
      return {
        isValid: true,
      };
    } else {
      return {
        isValid: false,
        message: `Only ${fileFormat.join(', ')} file formats are supported.`
      };
    }
  }

}
export interface OrgQueryString {
  pageSize?: string;
  pageNumber?: string;
  sortOrder?: string;
  filter: string;
  areaId?: string;
}
export class QueryString {
  constructor() { }
  name?: string;
  email?: string;
  searchText?: string;
  limit?: string;
  page?: string;
  role?: number;
  organization?: string;
  holdId?: number;
  season?: any;
  year?: any;
}
