import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ManageAreaService {
  constructor(private httpService: HttpService) {}
  getAll(status?: any) {
    return this.httpService.httpGet('/area/all');
  }
  getAllRegions(area_id?: any) {
    const params = new HttpParams().set('area_id', area_id);
    return this.httpService.httpGet('/region/all', params);
  }

  getAllVideoCategories(area_id?: any) {
    const params = new HttpParams().set('area_id', area_id);
    return this.httpService.httpGet('/videoCategory/all', params);
  }

  getAllActive() {
    return this.httpService.httpGet('/area/all-active');
  }

  addArea(area: any) {
    return this.httpService.httpPost('/area/create', area);
  }
  addRegion(region: any) {
    return this.httpService.httpPost('/region/create', region);
  }
  getReasons(type?: any) {
    return this.httpService.httpGet(`/reason/all-records?type=${type}`);
  }
  addReason(region: any) {
    return this.httpService.httpPost('/reason/create', region);
  }
  addVideo(region: any) {
    return this.httpService.httpPost('/video/create', region);
  }

  addVideoCategory(region: any) {
    return this.httpService.httpPost('/videoCategory/create', region);
  }
  getAreasByRegion(data: any) {
    return this.httpService.httpPost('/areas/by/region', data);
  }
  updateArea(area: any) {
    return this.httpService.httpPost('/area/update', area);
  }
  updateRegion(region: any) {
    return this.httpService.httpPost('/region/update', region);
  }
  updateReason(region: any) {
    return this.httpService.httpPost('/reason/update', region);
  }

  updateVideoCategory(videoCategory: any) {
    return this.httpService.httpPost('/videoCategory/update', videoCategory);
  }

  updateVideo(videoCategory: any) {
    return this.httpService.httpPost('/video/update', videoCategory);
  }

  deleteVideo(video: any) {
    return this.httpService.httpPost('/video/delete', video);
  }

  changeAreaStatus(area: any) {
    return this.httpService.httpPost('/area/change/status', area);
  }
  changeRegionStatus(area: any) {
    return this.httpService.httpPost('/region/change/status', area);
  }
  changeReasonStatus(area: any) {
    return this.httpService.httpPost('/reason/change/status', area);
  }
  deleteArea(area: any) {
    return this.httpService.httpPost('/area/delete', area);
  }
  deleteRegion(region: any) {
    return this.httpService.httpPost('/region/delete', region);
  }
  deleteReason(reason: any) {
    return this.httpService.httpPost('/reason/delete', reason);
  }
  deleteVideoCategory(region: any) {
    return this.httpService.httpPost('/videoCategory/delete', region);
  }
}
