import { HttpService } from './http.service';
import { ApplicationRef, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserLoginInfo } from 'src/app/models/user';
import { Base64 } from 'js-base64';
@Injectable({
  providedIn: 'root'
})
export class UserAuthService {

  private currentUserSubject: BehaviorSubject<UserLoginInfo>;
  public currentUser: Observable<UserLoginInfo>;

  constructor(
    private httpService: HttpService,
    private router: Router) {
    const bs604de: string | null = (localStorage.getItem('itIsOow')) ?
      Base64.decode(localStorage.getItem('itIsOow') as string) : null;
    this.currentUserSubject = new BehaviorSubject<UserLoginInfo>(JSON.parse(bs604de as string));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): UserLoginInfo {
    return this.currentUserSubject.value;
  }

  public set currentUserValue(userData: UserLoginInfo) {
    this.currentUserSubject.next(userData);
  }

  login(user: any) {
    return this.httpService.httpPost('/auth/signin', user).pipe(map((userData: any) => {
      const loggedUser: UserLoginInfo = userData.data;
      delete loggedUser['uroles'];
      if (loggedUser && loggedUser.token) {
        const bs604en: string = Base64.encode(JSON.stringify(loggedUser));
        localStorage.setItem('itIsOow', bs604en);
        localStorage.setItem('llt', new Date().getTime().toString());
        this.currentUserValue = loggedUser;
      }
      return userData;
    }), catchError(err => throwError(err)));
  }

  createPassword(user: any, token: any) {
    return this.httpService.httpPost('/user/create/pwd/' + token, user);
  }

  forgotPassword(data: any) {
    return this.httpService.httpPost('/user/forgot/password', data);
  }

  isLoggednIn() {
    return this.currentUserValue !== null;
  }

  getLoggedinUserInfo() {
    return this.httpService.httpGet('/loggedin/user/info');
  }

  logout(data?: any) {
    return this.httpService.httpPost('/auth/signout', data);
  }

  systemLock() {
    window.location.href = window.location.hostname + '/#/system-locked';
  }
  getIPAddress() {
    return this.httpService.thirdPartyURL(`https://api.ipify.org/?format=json`);
  }
}
