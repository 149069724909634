import { environment } from './../../../environments/environment';
import { OrgQueryString } from './common.service';
import { HttpService } from './http.service';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ManageOrganizationService {

  constructor(private httpService: HttpService, private http: HttpClient) { }

  getAll(querString?: OrgQueryString) {
    // const params = this.commonService.createOrgParams(querString);
    return this.httpService.httpGet('/organization/all');
  }
  organizationUserAssocation(association: any) {
    return this.httpService.httpPost('/organization/user/association', association);
  }
  getAllp(sort: string, order: string, page: number) {
    const requestUrl = (sort) ? `?sort=${sort}&order=${order}&page=${page + 1}` : '';
    return this.httpService.httpGet('/organization/all' + requestUrl);
  }
  getAllByYearAndSeason(year: string, season: string) {
    const requestUrl = `?season=${season}&year=${year}`;
    return this.httpService.httpGet('/organizations/by/season/year' + requestUrl);
  }
  addOrganization(organization: any) {
    return this.httpService.httpPost('/organization/create', organization);
  }
  proxyLoginRequest(organization: any) {
    return this.httpService.httpPost('/organization/proxy-login-request', organization);
  }

  updateOrganization(organization: any) {
    return this.httpService.httpPost('/organization/update', organization);
  }
  updateBulkConfig(data: any) {
    return this.httpService.httpPost('/organization/set/bulk/config', data);
  }

  changeOrganizationStatus(organization: any) {
    return this.httpService.httpPost('/organization/change/status', organization);
  }
  resetCount(objectOfIds: any) {
    return this.httpService.httpPost('/organization/sendandsms/clear', objectOfIds);
  }
  bulkOrganizationPayVolunteer(objectOfIds: any) {
    return this.httpService.httpPost('/organization/bulk/pays/volunteer', objectOfIds);
  }
  bulkOrganizationPayAthlete(objectOfIds: any) {
    return this.httpService.httpPost('/organization/bulk/pays/athlete', objectOfIds);
  }
  changeBulkOrganizationStatus(statuswithIds: any) {
    return this.httpService.httpPost('/organization/change/bulk/status', statuswithIds);
  }
  changeBulkOrganizationLockStatus(statuswithIds: any) {
    return this.httpService.httpPost('/organization/change/bulk/lock', statuswithIds);
  }

  deleteOrganization(organization: any) {
    return this.httpService.httpPost('/organization/delete', organization);
  }

  validateEmail(orgEmail: any) {
    return this.httpService.httpPost('/organization/validateEmail', orgEmail);
  }

  validateOrg(orgName: any) {
    return this.httpService.httpPost('/organization/validateOrg', orgName);
  }
  updateTask(task: any) {
    return this.httpService.httpPost('/tasks/update', task);
  }
  filterData(filterValue: number) {
    return this.httpService.httpGet('/organization/filter?area_id=' + filterValue);
  }
  checkIfAthleteOrVolunteerAssociatedWithOrganization(organization: any) {
    return this.httpService.httpPost('/organization/check-if-athlete-volunteer/associated', organization);
  }
  sendMail(objectOfIds: any) {
    return this.httpService.httpPost('/organization/send/email', objectOfIds);
  }

  sendSMS(objectOfIds: any) {
    return this.httpService.httpPost('/organization/send/sms', objectOfIds);
  }
  finalizeOrganizations() {
    return this.httpService.httpPost('/organization/finalize', {});
  }

  importOrganization(fileobj: File) {
    const apiCreateEndpoint = `${environment.apiUrl}/organization/import`;
    const formData: FormData = new FormData();

    formData.append('fileItem', fileobj, fileobj.name);

    const req = new HttpRequest('POST', apiCreateEndpoint, formData, {
      reportProgress: true
    });
    return this.http.request(req).pipe(
      map(event => this.getEventMessage(event, formData)),
      catchError(this.handleError)
    );

  }

  importYCADAVolunteers(fileobj: File) {
    const apiCreateEndpoint = `${environment.apiUrl}/ycada/volunteers/import`;
    const formData: FormData = new FormData();

    formData.append('fileItem', fileobj, fileobj.name);

    const req = new HttpRequest('POST', apiCreateEndpoint, formData, {
      reportProgress: true
    });
    return this.http.request(req).pipe(
      map(event => this.getEventMessage(event, formData)),
      catchError(this.handleError)
    );
  }

  exportOrganization() {
    return this.httpService.httpGet('/organization/export');
  }

  private getEventMessage(event: HttpEvent<any>, formData) {

    switch (event.type) {

      case HttpEventType.UploadProgress:
        return this.fileUploadProgress(event);

      case HttpEventType.Response:
        return this.apiResponse(event);

      default:
        return `File "${formData.get('fileItem').name}" surprising upload event: ${event.type}.`;
    }
  }

  private fileUploadProgress(event) {
    const percentDone = Math.round(100 * event.loaded / event.total);
    return { status: 'progress', message: percentDone };
  }

  private apiResponse(event) {
    return event.body;
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened. Please try again later.');
  }

}
