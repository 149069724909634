import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { CommonService } from './common.service';
import { HttpParams } from '@angular/common/http';
import { Base64 } from 'js-base64';
import { UserAuthService } from '.';

@Injectable({
  providedIn: 'root'
})
export class MasterService {
  private currentUser: any;
  constructor(
    private httpService: HttpService,
    private commonService: CommonService,
    private userAuthService: UserAuthService) {
    // this.userAuthService.currentUser.subscribe((userData: any) => {
    //   this.currentUser = userData;
    // });
  }
  getAllGenders() {
    return this.httpService.httpGet('/gender/all');
  }
  getSignedURL(key: string) {
    return this.httpService.httpGet(`/get/signed-url?key=${key}`);
  }
  getAllGenerations() {
    return this.httpService.httpGet('/generation/all');
  }

  getAllPoloSizes() {
    return this.httpService.httpGet('/polosize/all');
  }
  getAllRaces() {
    return this.httpService.httpGet('/race/all');
  }

  getAllTshirSizes() {
    return this.httpService.httpGet('/tshirtsize/all');
  }

  getAllSports() {
    return this.httpService.httpGet('/sport/all');
  }

  getAllBrackets(id: number, organization_id?: string) {
    return this.httpService.httpGet(`/bracket/all/${id}?organization_id=${organization_id}`);
  }
  getAllExecutiveHolds(type: string) {
    return this.httpService.httpGet('/executive-holds/all?type=' + type);
  }

  getOrgOptions(data?: any) {
    let params = new HttpParams();
    if (data) {
      if (data.type) {
        // this type is tyfa:1,non-tyfa:2,test:3, default:0
        params = params.append('type', data.type);
      }
      if (data.area_id) {
        params = params.append('area_id', data.area_id);
      }
      if (data.test_required) {
        params = params.append('test_required', data.test_required);
      }
      if (data.test_required === false) {
        params = params.append('test_required', data.test_required);
      }
      if (data.all) {
        params = params.append('all', data.all);
      }
    }
    return this.httpService.httpGet('/organization/options', params);
  }

  getAllTitlesById(spotid: number) {
    return this.httpService.httpGet('/title/all/' + spotid);
  }
  getAllStates() {
    return this.httpService.httpGet('/states/all');
  }

  findPersions(queryString: any) {
    const params = this.commonService.createParams(queryString);
    return this.httpService.httpGet('/find/persion', params);
  }
  athletePayment(athlete: any) {
    return this.httpService.httpPost('/athlete/admin-payment', athlete);
  }

  uploadAthletePhoto(athlete: any) {
    return this.httpService.httpPost('/athlete/upload-photo', athlete);
  }

  getPaidParticipants(participants: any) {
    return this.httpService.httpPost('/participant/paid-list', participants);
  }

  getvPaidParticipants(participants: any) {
    return this.httpService.httpPost('/participant/vpaid-list', participants);
  }

  volunteerPayment(volunteer: any) {
    return this.httpService.httpPost('/volunteer/admin-payment', volunteer);
  }

  getTransactionDetails(pid: number) {
    return this.httpService.httpGet('/authorizenet/transaction-details/' + pid);
  }


  uploadVolunteerPhoto(volunteer: any) {
    return this.httpService.httpPost('/volunteer/upload-photo', volunteer);
  }

  deleteVolunteer(volunteers: any) {
    return this.httpService.httpPost('/volunteer/delete', volunteers);
  }
  deleteAthletes(athletes: any) {
    return this.httpService.httpPost('/athlete/delete', athletes);
  }
  deletePicture(athletes: any) {
    return this.httpService.httpPost('/athlete/delete-picture', athletes);
  }
  deleteBC(athletes: any) {
    return this.httpService.httpPost('/athlete/delete-bc', athletes);
  }
  deleteBCDocs(athletes: any) {
    return this.httpService.httpPost('/athlete/delete-bc-docs', athletes);
  }
  deletePhotoId(volunteer: any) {
    return this.httpService.httpPost('/volunteer/delete-photoid', volunteer);
  }
  deletePhotoIds(volunteer: any) {
    return this.httpService.httpPost('/volunteer/delete-docs', volunteer);
  }
  deleteVolunteerPicture(volunteers: any) {
    return this.httpService.httpPost('/volunteer/delete-picture', volunteers);
  }
  get Sports() {
    return [
      { id: null, name: 'All Sports', checked: false, status: false },
      ...this.SportsWithoutFirstItem
    ];
  }
  get SportsWithoutFirstItem() {
    return [
      { id: 2, name: 'Cheer', checked: false, status: true },
      { id: 3, name: 'Football', checked: false, status: true },
      { id: 4, name: 'Both', checked: false, status: true }
    ];
  }
  getBrackets(sportId: any, organization?: any) {
    const localData = JSON.parse(Base64.decode(localStorage.getItem('itIsOow') || '{}'));
    const brackets = localData.brackets;
    if (sportId && !organization) {
      return brackets.filter(b => b.sport_id === +sportId);
    } else if (sportId && organization) {
      return brackets.filter(b => b.sport_id === +sportId && b.is_open_bracket === organization.is_open_organization);
    } else {
      return brackets;
    }

  }
  getRoles() {
    const roles = [{ id: 1, name: 'TYFA', satus: 1 },{ id: 3, name: 'Athlete', satus: 1 }, { id: 2, name: 'Volunteer', satus: 1 } ]
    return {
      getRolesWithoutTYFARole: () => roles.filter(r => r.name !== 'TYFA'),
      getAllRoles: () => roles
    }
  }

}
