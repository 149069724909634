import { Injectable } from '@angular/core';
import { Observable } from 'rxjs'; // only need to import from rxjs
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

@Injectable()
export class DialogsService {

  constructor(private dialog: MatDialog) { }

  public confirm(title: string, message: string, reject?: boolean, enable_reason_section?: boolean): Observable<boolean> {

    let dialogRef: MatDialogRef<ConfirmDialogComponent>;

    dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { reject, enable_reason_section },
      width: '424px',
      backdropClass: 'alert_box',
      hasBackdrop: true,
      panelClass: 'custom_alert_dialog'
    });
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.reject = reject;
    return dialogRef.afterClosed();
  }
  public delete_picture_confirm(confirmationProp: any): Observable<boolean> {

    let dialogRef: MatDialogRef<ConfirmDialogComponent>;

    dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { reject: confirmationProp.reject, enable_reason_section: confirmationProp.enable_reason_section },
      width: '724px',
      backdropClass: 'alert_box',
      hasBackdrop: true,
      panelClass: 'custom_alert_dialog'
    });
    dialogRef.componentInstance.title = confirmationProp.title;
    dialogRef.componentInstance.message = confirmationProp.message;
    dialogRef.componentInstance.reject = confirmationProp.reject;
    dialogRef.componentInstance.reasons = confirmationProp.reasonsToDelete;
    
    return dialogRef.afterClosed();
  }
}
