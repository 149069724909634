import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { SystemConfiguration } from '../../models/system-configuration';
import { ScholasticSystemConfiguration } from 'src/app/models/scholastic-system-config';
@Injectable({
  providedIn: 'root'
})
export class ManageUserService {

  constructor(private httpService: HttpService) { }

  getAll() {
    return this.httpService.httpGet('/user/all');
  }
  createOrUpdateScholasticSystemConfiguration(scholasticSystemConfiguration: ScholasticSystemConfiguration) {
    return this.httpService.httpPost('/scholasticsystem/configuration/createorupdate', scholasticSystemConfiguration);
  }
  getScholasticConfiguration() {
    return this.httpService.httpGet('/system/scholasticsystem');
  }

  getSystemConfiguration() {
    return this.httpService.httpGet('/system/configuration');
  }
  createOrUpdateSystemConfiguration(configuration: SystemConfiguration) {
    return this.httpService.httpPost('/system/configuration/createorupdate', configuration);
  }
  updateHiatusHoldStatus(configuration: SystemConfiguration) {
    return this.httpService.httpPost('/change/hold-hiatus-status', configuration);
  }
  updateUploadPictures(configuration: SystemConfiguration) {
    return this.httpService.httpPost('/change/upload-pictures-enable-disable', configuration);
  }
  updateExamHoldStatus(configuration: SystemConfiguration) {
    return this.httpService.httpPost('/change/tyfa-exam-status', configuration);
  }
  updateTakePictureStatus(configuration: SystemConfiguration) {
    return this.httpService.httpPost('/change/take-picture-status', configuration);
  }
  // updateHitcheckHoldStatus(configuration: SystemConfiguration) {
  //   return this.httpService.httpPost('/change/hold-hitcheck-status', configuration);
  // }

  getUsers() {
    return this.httpService.httpGet('/users');
  }
  updateBgchkStatus() {
    return this.httpService.httpGet('/screening/status');
  }
  updateYCADAHoldStatus(configuration: SystemConfiguration) {
    return this.httpService.httpPost('/change/hold-ycada-status', configuration);
  }
}
