import { HttpService } from './http.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private httpService: HttpService) { }
  getAll() {
    return this.httpService.httpGet('/role/all');
  }

  validateRole(orgName: any) {
    return this.httpService.httpPost('/role/validateRole', orgName);
  }
}
