<div class="custom_dialog_content">
    <h2 class="alert_title">{{ title }}</h2>
    <p class="alert_message">{{ message }}</p>
</div>
<div class="custom_dialog_actions">
    <div *ngIf="enable_reason_section">
        <mat-select  placeholder="Select Reasons" style="max-width:calc(100%) !important;" color="primary" [(ngModel)]="selected_reasons" multiple [ngModelOptions]="{standalone: true}">
        <mat-option *ngFor="let reason of reasons" style="max-width:calc(100%) !important;" [value]="reason.id">{{reason.reason}}
        </mat-option>
      </mat-select>
        <mat-error *ngIf="!selected_reasons || (selected_reasons && selected_reasons.length<1)">
           Please select atleast one reason
        </mat-error>
    </div>
    <div *ngIf="!reject" class="bottom_buttons secondary">
        <button type="button" mat-stroked-button color="primary" (click)="dialogRef.close()">Cancel</button>
        <button type="button" *ngIf="!enable_reason_section" mat-flat-button color="primary" (click)="dialogRef.close(true)">Confirm</button>
        <button type="button" *ngIf="enable_reason_section" mat-flat-button color="primary" (click)="closeDialog()">Confirm</button>
    </div>
    <div *ngIf="reject" class="bottom_buttons secondary">
        <button type="button" mat-flat-button color="primary" (click)="dialogRef.close(true)">OK</button>
    </div>
</div>