import { Injectable } from '@angular/core';
import { SubDataService } from './sub-data.service';
import { ModulePermissions, Permission } from 'src/app/models/permission';
import { Appmodule } from './../../models/appmodule';
import { take } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ModulePermissionsService {

  constructor(private subDataService: SubDataService) { }
  getUserModPermission(modname: string, callback: Function) {
    const mp: ModulePermissions = { view: false, edit: false, delete: false, clearHolds: false };
    let currentmodp: Array<Permission> = [];
    this.subDataService.loggedInUserInfo.pipe(take(1)).subscribe((modNPermRes: Array<Appmodule>) => {
      const currentmod: Array<Appmodule> =
        modNPermRes.filter((elmnt: Appmodule) => {
          if (elmnt.status && elmnt.title === modname) { return elmnt; }
          return null;
        });
      if (currentmod.length > 0) {
        currentmodp = currentmod[0].permissions as Array<any>;
        currentmodp.forEach((cm: Permission) => {
          if (cm.name === 'view') {
            mp.view = true;
          }
          if (cm.name === 'edit') {
            mp.edit = true;
          }
          if (cm.name === 'delete') {
            mp.delete = true;
          }
          if (cm.name === 'clearHolds') {
            mp.clearHolds = true;
          }
        });
      }
      callback(mp);
    });
  }
}
