<div class="export_container">
    <div class="container-fluid">
        <div class="org_select">
            <mat-form-field appearance="outline" color="primary">
                <mat-select placeholder="Select Organization" [(ngModel)]="selectedOrganization">
                    <mat-option *ngFor="let organization of organizations" [value]="organization.id">
                        {{organization.organizationName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="row secondary">

            <div class="col-md-6">
                <div class="export_inner">
                    <div class="middle_icon volunteer_icon">
                        <mat-icon>group</mat-icon>
                    </div>
                    <div class="btn_row">
                        <button type="button" (click)="downloadVolunteers($event)" mat-raised-button color="accent"
                            class="export_button">Export Volunteers</button>
                    </div>
                    <div class="export_text">Export the list of volunteers
                        <br> added in the system.</div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="export_inner">
                    <div class="middle_icon athlete_icon">
                        <mat-icon>directions_run</mat-icon>
                    </div>
                    <div class="btn_row">
                        <button type="button" (click)="downloadAthletes($event)" mat-raised-button color="primary"
                            class="export_button">Export Athletes</button>
                    </div>
                    <div class="export_text">Export the list of athletes
                        <br> added in the system.</div>
                </div>
            </div>
        </div>
    </div>
</div>