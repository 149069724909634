import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Bracket } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class ManageBracketService {

  constructor(private httpService: HttpService) { }

  getAll(sportId) {
    return this.httpService.httpGet('/bracket/all/' + sportId);
  }

  addBracket(bracket: Bracket) {
    return this.httpService.httpPost('/bracket/create', bracket);
  }

  updateBracket(bracket: Bracket) {
    return this.httpService.httpPost('/bracket/update', bracket);
  }

  changeBracketStatus(bracket: Bracket) {
    return this.httpService.httpPost('/bracket/change/status', bracket);
  }

  deleteBracket(bracketIds: any) {
    return this.httpService.httpPost('/bracket/delete-bracket', bracketIds);
  }

  isAsociation(bids: any) {
    return this.httpService.httpPost('/bracket/association', bids);
  }


}
