import { NotificationService } from './notification.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ErrorsHandlerService implements ErrorHandler {

  constructor(
    // Because the ErrorHandler is created before the providers, we’ll have to use the Injector to get them.
    private injector: Injector) { }
  handleError(error: Error | HttpErrorResponse) {
    const notificationService = this.injector.get(NotificationService);
    this.injector.get(Router);
    if (error instanceof HttpErrorResponse) {
      // Server or connection error happened
      if (!navigator.onLine) {
        // Handle offline error
        return notificationService.notify('No Internet Connection');
      } else {
        // Handle Http Error (error.status === 403, 404...)
        return notificationService.notify(`${error.status} - ${error.message}`);
      }
    } else {
      // Handle Client Error (Angular Error, ReferenceError...)
      // router.navigate(['/'], { queryParams: { error: error } });
      console.log(error);
    }
    // Log the error anyway
    // console.error('It happens: ', error);
  }
}
