import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
// import 'rxjs/add/operator/publish';
@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private _notification: BehaviorSubject<string> = new BehaviorSubject('');
  // readonly notification$: Observable<string> = this._notification.asObservable().publish().refCount();
  readonly notification$: Observable<string> = this._notification.asObservable();

  constructor() {}

  notify(message: any) {
    this._notification.next(message);
    setTimeout(() => this._notification.next(''), 3000);
  }
}
