import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PrintRosterComponent } from './print-roster/print-roster.component';
import { PrintAthleteComponent } from './print-roster/print-athlete/print-athlete.component';
import { PrintVolunteerComponent } from './print-roster/print-volunteer/print-volunteer.component';
import { PrintVolunteerTransactionComponent } from './print-roster/print-volunteer-transaction/print-volunteer-transaction.component';
import { PrintAthleteTransactionComponent } from './print-roster/print-athlete-transaction/print-athlete-transaction.component';

const routes: Routes = [
  { path: '', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  { path: '', loadChildren: () => import('./superadmin/superadmin.module').then(m => m.SuperadminModule) },
  {
    path: 'print',
    outlet: 'print',
    component: PrintRosterComponent,
    children: [
      { path: 'athlete-roster/:participantIds', component: PrintAthleteComponent },
      { path: 'volunteer-roster/:participantIds', component: PrintVolunteerComponent },
      { path: 'volunteer-transaction/:participantIds', component: PrintVolunteerTransactionComponent },
      { path: 'athlete-transaction/:participantIds', component: PrintAthleteTransactionComponent }
    ]
  },
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
