import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Reason } from 'src/app/models';
@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  public title: string;
  public message: string;
  public reject: boolean | undefined;
  public enable_reason_section: boolean | undefined;
  selected_reasons : any;
  reasons: Array<Reason>;
  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.reject = this.data.reject;
    this.enable_reason_section=this.data.enable_reason_section;
    this.reasons=data.reasons;
  }

  ngOnInit() {
  }
  closeDialog()
  {
    if(this.selected_reasons && this.selected_reasons.length>0)
    {
      this.dialogRef.close(this.reasons.filter((reason: Reason)=>{
        for (let index = 0; index < this.selected_reasons.length; index++) {
          const element = this.selected_reasons[index];
          if(element===reason.id)
          {
            return reason;
          }
        }
      }));
    }
  }

}
