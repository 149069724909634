import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material/material.module';
import { ApiLoaderComponent } from './shared/api-loader/api-loader.component';
import { ConfirmDialogComponent } from './shared/confirm-dialog/confirm-dialog.component';
import { AuthGuard } from './shared/guard/auth.guard';
import { AppHttpInterceptor } from './shared/services/AppHttpInterceptor';
import { ApiLoaderService } from './shared/services/api-loader.service';
import { CommonService } from './shared/services/common.service';
import { DialogsService } from './shared/services/dialogs.service';
import { ErrorsHandlerService } from './shared/services/errors-handler.service';
import { ModulePermissionsService } from './shared/services/module-permissions.service';
import { NotificationService } from './shared/services/notification.service';
import { CustomValidatorsDirective } from './shared/validators/custom-validator.directive';
import { ExportRostersComponent } from './superadmin/export-rosters/export-rosters.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule, MatSpinner, } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgHttpLoaderModule } from 'ng-http-loader';
// import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PrintRosterModule } from './print-roster/print-roster.module';

@NgModule({
  declarations: [
    AppComponent,
    CustomValidatorsDirective,
    ConfirmDialogComponent,
    ApiLoaderComponent,
    ExportRostersComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    AppRoutingModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    FormsModule,
    // BsDatepickerModule.forRoot(),
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    PrintRosterModule
  ],
  exports: [ConfirmDialogComponent, ApiLoaderComponent, CustomValidatorsDirective],
  providers: [
    ApiLoaderService,
    DialogsService,
    CommonService, AuthGuard,
    NotificationService, ModulePermissionsService,
    { provide: ErrorHandler, useClass: ErrorsHandlerService },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
