import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import {Title } from '../../models';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpParams, HttpRequest, HttpEventType } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, throwError} from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ManageTitleService {

  constructor(private http: HttpClient, private httpService: HttpService) { }
  getAll() {
    return this.httpService.httpGet('/title/all');
  }

  addTitle(title: Title) {
    return this.httpService.httpPost('/title/create', title);
  }

  updateTitle(title: Title) {
    return this.httpService.httpPost('/title/update', title);
  }

  changeTitleStatus(title: Title) {
    return this.httpService.httpPost('/title/change/status', title);
  }

  deleteTitle(titleIds: any) {
    return this.httpService.httpPost('/title/delete', titleIds);
  }
  exportTitles() {
    return this.httpService.httpGet('/title/export');
  }
  importTitles(fileobj: File) {
    const apiCreateEndpoint = `${environment.apiUrl}/title/upload`;
    const formData: FormData = new FormData();

    formData.append('fileItem', fileobj, fileobj.name);

    const req = new HttpRequest('POST', apiCreateEndpoint, formData, {
      reportProgress: true
    });
    return this.http.request(req).pipe(
      map(event => this.getEventMessage(event, formData)),
      catchError(this.handleError)
    );

  }
  private getEventMessage(event: HttpEvent<any>, formData) {

    switch (event.type) {

      case HttpEventType.UploadProgress:
        return this.fileUploadProgress(event);

      case HttpEventType.Response:
        return this.apiResponse(event);

      default:
        return this.apiResponse(event);
    }
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }
  private fileUploadProgress(event) {
    const percentDone = Math.round(100 * event.loaded / event.total);
    return { status: 'progress', message: percentDone };
  }

  private apiResponse(event) {
    return event.body;
  }

}
