import { Component, OnInit } from '@angular/core';
import { PrintService } from 'src/app/shared/services/print.service';
import { ActivatedRoute } from '@angular/router';
import { Volunteers } from 'src/app/models';
import { Volunteerexecutivehold } from 'src/app/models/volunteerexecutivehold';
import { VolunteerHolds } from 'src/app/models/volunteer-holds';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-print-volunteer',
  templateUrl: './print-volunteer.component.html',
  styleUrls: ['./print-volunteer.component.scss']
})
export class PrintVolunteerComponent implements OnInit {
  participants: Array<Volunteers>;
  constructor(private route: ActivatedRoute, private printService: PrintService,private _sanitizer: DomSanitizer, private commonService: CommonService) { }

  ngOnInit() {
    const participantIds: number[] = this.route.snapshot.params['participantIds'].split(',');

    this.printService.volunteerRoster({ ids: participantIds }).subscribe(
      async (participants: any) => {
        this.participants = participants.data;
        for (let index = 0; index < this.participants.length; index++) {
          const element = this.participants[index];
          if(element.photoURL)
          {
            const base64image= await this.commonService.toDataURL(element.photoURL);;
            element.photoSafeURL = this._sanitizer.bypassSecurityTrustResourceUrl(base64image);
            
          }
          element.hasHold = this.hasVounteerHolds(element);
          element.hasCOVID = this.hasCOVID(element);
        }
        this.printService.onDataReady();
      });
  }
  hasVounteerHolds(element: Volunteers) {
    if (this.haveVounteerteHolds(element.volunteer_hold) || this.checkVounteerexecutiveholds(element.volunteerexecutiveholds)) {
      return true;
    } else {
      return false;
    }
  }
  haveVounteerteHolds(volunteer_hold: VolunteerHolds) {
    if (volunteer_hold && volunteer_hold.isProfileCompleted && volunteer_hold.isPhotoUploaded && volunteer_hold.isPaymentReceived && volunteer_hold.isBackgroundCheckCompleted
      && volunteer_hold.isContractSigned && volunteer_hold.isTyfaExamCompleted && volunteer_hold.hasYCADA==false) {
      return false;
    } else {
      return true;
    }
  }
  checkVounteerexecutiveholds(volunteerexecutiveholds: Array<Volunteerexecutivehold>) {
    if (volunteerexecutiveholds.length > 0 && volunteerexecutiveholds.find(p => p.cleared === false)) {
      return true;
    } else {
      return false;
    }
  }
  hasCOVID(element: Volunteers) {
    if (element.volunteerexecutiveholds && element.volunteerexecutiveholds.length) {
      return element.volunteerexecutiveholds.find(a => a.cleared === false && a.executivehold.id === 5) ? true : false;
    } else {
      return false;
    }
  }
}
