import { NotificationService } from './shared/services/notification.service';
import { Component, OnInit } from '@angular/core';
import { MatSpinner } from '@angular/material/progress-spinner';
import { Spinkit, SpinnerVisibilityService } from 'ng-http-loader';
import { SwUpdate } from '@angular/service-worker';
import { PrintService } from './shared/services/print.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';
  public matSpinner = MatSpinner;
  notification: string;
  showNotification: boolean;
  update = false;
  isConnected = true;
  // public promptEvent;
  // @HostListener('window:beforeinstallprompt', ['$event'])

  public spinkit = Spinkit; // <============
  constructor(private notificationService: NotificationService, private spinner: SpinnerVisibilityService, private swupdates: SwUpdate, public printService: PrintService) {
    this.spinner.hide();
    this.swupdates.available.subscribe(event => {
      swupdates.activateUpdate().then(() => document.location.reload());
    });
  }

  // onbeforeinstallprompt(e) {
  //   e.preventDefault();
  //   this.promptEvent = e;
  // }

  // public installPWA() {
  //   this.promptEvent.prompt();
  // }

  // public shouldInstall(): boolean {
  //   return !this.isRunningStandalone() && this.promptEvent;
  // }

  // public isRunningStandalone(): boolean {
  //   return (window.matchMedia('(display-mode: standalone)').matches);
  // }

  ngOnInit() {
    this.notificationService.notification$.subscribe((message: any) => {
      this.notification = message;
      this.showNotification = true;
    });
    if (!navigator.onLine) {
      this.isConnected = false;
    } else {
      this.isConnected = true;
    }
  }
}

