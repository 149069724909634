import { LoaderState } from '../../models/loader-state';
import { ApiLoaderService } from '../services/api-loader.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-api-loader',
  templateUrl: './api-loader.component.html',
  styleUrls: ['./api-loader.component.scss']
})
export class ApiLoaderComponent implements OnInit, OnDestroy {
  show = false;

  private subscription: Subscription;

  constructor(private loaderService: ApiLoaderService) { }

  ngOnInit() {
    this.subscription = this.loaderService.loaderState
      .subscribe((state: LoaderState) => {
        this.show = state.show;
      });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
