<div class="listing">
    <div class="row d-flex flex-wrap">
        <div class="col-xl-3 col-lg-4 col-md-6 col-xs-12 print_col" *ngFor="let row of participants">
            <mat-card class="athlete_card">
                <mat-card-content>
                    <div class="single_athlete">
                        <div class="athlete_photo">
                            <div class="circle_image">
                                <div *ngFor="let volhold of row?.volunteerexecutiveholds">
                                    <div *ngIf="(volhold?.executivehold?.name=='Disciplinary') && (!volhold?.cleared)"
                                        class="hold_status">Suspended</div>
                                </div>
                                <div *ngIf="!row.volunteer_hold.isCertified" class="hold_status">Not Certified</div>
                                <span class="participant_photo" *ngIf="row.photoURL && !row.hasCOVID">
                                    <img [src]="row.photoSafeURL" onerror="this.src='../../../../assets/images/user.png';">
                                </span>
                                <span class="participant_photo_default" *ngIf="!row.photoURL || row.hasCOVID">
                                    <img src="../../../../assets/images/user.png"
                                        onerror="this.src='../../../../assets/images/user.png';" alt="">
                                </span>
                            </div>
                        </div>
                        <div class="athlete_card_details">
                            <h4 class="athlete_name"><a
                                    [routerLink]="['/admin/manage-participants/volunteer-profile',row.id]"
                                    target="_blank">{{row.firstName}}
                                    {{row.lastName}}</a></h4>
                            <div class="athlete_info">
                                <div class="info_heading">Status</div>
                                <div *ngIf="row.volunteer_hold" class="info_desc" [ngClass]="{
                          'certified': row.volunteer_hold.isCertified,
                          'not_certified': !row.volunteer_hold.isCertified
                        }">{{(row.volunteer_hold.isCertified)?'Certified':'Not
                      Certified'}}</div>
                                <div *ngIf="!row.volunteer_hold" class="info_desc not_certified">Not Certified</div>
                            </div>
                            <div class="athlete_info">
                                <div class="info_heading">Titles</div>
                                <div class="info_desc">
                                    <span>{{(row.primarytitleId)?row.primarytitleId.description:''}}</span>
                                    <span *ngIf="row.primarytitleId && row.secondarytitleId">, </span>
                                    <span>{{(row.secondarytitleId)?row.secondarytitleId.description:''}}</span>
                                    <span *ngIf="!row.primarytitleId && !row.secondarytitleId">NA</span>
                                </div>
                            </div>
                            <div class="holds print_only">
                                <h5>Holds</h5>
                                <span>{{row.hasHold?'Volunteer has holds':'Volunteer has no holds'}}</span>
                            </div>
                            <div class="view_only">
                                <h5 *ngIf="row.hasHold">Holds</h5>
                                <mat-chip-list>
                                    <mat-chip color="accent"
                                        *ngIf="(row.volunteer_hold)?!row.volunteer_hold.isPhotoUploaded:true"
                                        selected="true">Photo</mat-chip>
                                    <mat-chip color="accent"
                                        *ngIf="(row.volunteer_hold)?!row.volunteer_hold.isPaymentReceived:true"
                                        selected="true">Payment</mat-chip>
                                    <mat-chip color="accent"
                                        *ngIf="(row?.volunteer_hold)?!row.volunteer_hold.isContractSigned:true"
                                        selected="true">Contract</mat-chip>
                                    <mat-chip color="accent"
                                        *ngIf="(row?.volunteer_hold)?!row.volunteer_hold.isBackgroundCheckCompleted:true"
                                        selected="true">
                                        <span>{{row.volunteer_hold.backgroundInitDate?'BG Pending':'BG not Run'}}</span></mat-chip>
                                    <mat-chip color="accent"
                                        *ngIf="(row?.volunteer_hold)?!row.volunteer_hold.isTyfaExamCompleted:true"
                                        selected="true">Exam</mat-chip>
                                        <mat-chip color="accent"
                                            *ngIf="(row?.volunteer_hold)?row.volunteer_hold.hasYCADA:false" selected="true">
                                            YCADA</mat-chip>
                                    <div *ngFor="let volunteerexecutivehold of row.volunteerexecutiveholds">
                                        <mat-chip color="accent" *ngIf="!volunteerexecutivehold.cleared && volunteerexecutivehold.executivehold.id!==5"
                                            selected="true">
                                            {{volunteerexecutivehold?.executivehold?.name}}</mat-chip>
                                    </div>
                                </mat-chip-list>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>