import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Appmodule } from 'src/app/models/appmodule';
@Injectable({
  providedIn: 'root'
})
export class SubDataService {
  athleteCommunicationFilterData = new BehaviorSubject<any>(null);
  athleteFilterData = new BehaviorSubject<any>(null);
  volunteerFilterData = new BehaviorSubject<any>(null);
  volunteerCommunicationFilterData = new BehaviorSubject<any>(null);
  selectedAthleteIds = new BehaviorSubject<any>(null);
  selectedCommunicationAthleteIds = new BehaviorSubject<any>(null);
  selectedRosterIds = new BehaviorSubject<any>(null);
  selectedAthleteParentIds = new BehaviorSubject(null);
  selectedCommunicationAthleteParentIds = new BehaviorSubject(null);
  selectedVolunteerIds = new BehaviorSubject<any>(null);
  selectedCommunicationVolunteerIds = new BehaviorSubject<any>(null);
  athletePayment = new BehaviorSubject<any>(null);
  volunteerPayment = new BehaviorSubject<any>(null);
  loggedInUserInfo = new BehaviorSubject<any>(null);
  constructor() { }
  setAthleteFilterData(data: any) {
    this.athleteFilterData.next(data);
  }
  setCommunicationAthleteFilterData(data: any) {
    this.athleteCommunicationFilterData.next(data);
  }

  setVolunteerFilterData(data: any) {
    this.volunteerFilterData.next(data);
  }
  setCommunicationVolunteerFilterData(data: any) {
    this.volunteerCommunicationFilterData.next(data);
  }

  setSelectedAthleteIds(data: any) {
    this.selectedAthleteIds.next(data);
  }
  setSelectedCommunicationAthleteIds(data: any) {
    this.selectedCommunicationAthleteIds.next(data);
  }

  setSelectedRosterIds(data: any) {
    this.selectedRosterIds.next(data);
  }

  setSelectedAthleteParentIds(data: any) {
    this.selectedAthleteParentIds.next(data);
  }
  setSelectedCommunicationAthleteParentIds(data: any) {
    this.selectedCommunicationAthleteParentIds.next(data);
  }

  setSelectedVolunteerIds(data: any) {
    this.selectedVolunteerIds.next(data);
  }
  setSelectedCommunicationVolunteerIds(data: any) {
    this.selectedCommunicationVolunteerIds.next(data);
  }
  setAthletePayment(data: any) {
    this.athletePayment.next(data);
  }
  setVolunteerPayment(data: any) {
    this.volunteerPayment.next(data);
  }
  setLoggedInUserDataInfo(user: Array<Appmodule>) {
    this.loggedInUserInfo.next(user);
  }
}
