import { Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrintService } from 'src/app/shared/services/print.service';
import { Athlete, AthleteHolds } from 'src/app/models';
import { Athleteexecutivehold } from 'src/app/models/athleteexecutivehold';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-print-athlete',
  templateUrl: './print-athlete.component.html',
  styleUrls: ['./print-athlete.component.scss']
})
export class PrintAthleteComponent implements OnInit{
  participants: Array<Athlete>;

  constructor(private route: ActivatedRoute, private printService: PrintService, private _sanitizer: DomSanitizer, 
    private commonService: CommonService) {
  }

  ngOnInit() {
    const participantIds: number[] = this.route.snapshot.params['participantIds'].split(',');
    this.printService.athleteRoster({ ids: participantIds }).subscribe(async (participants: any) => {
      this.participants = participants.data;
      for (let index = 0; index < this.participants.length; index++) {
        const element = this.participants[index];
        if(element.photoURL)
        {
          const base64image= await this.commonService.toDataURL(element.photoURL);;
          element.photoSafeURL = this._sanitizer.bypassSecurityTrustResourceUrl(base64image);
          
        }
        element.hasHold = this.hasAthleteHolds(element);
        element.hasCOVID = this.hasCOVID(element);
      }
      this.printService.onDataReady();
    });

  }
  // async toDataURL(url: string){
  //   var res = await fetch(url);
  //   var blob = await res.blob();

  //   const result = await new Promise<string>((resolve, reject) => {
  //     var reader = new FileReader();
  //     reader.addEventListener("load", function () {
  //       resolve(reader.result.toString());
  //     }, false);

  //     reader.onerror = () => {
  //       return reject(this);
  //     };
  //     reader.readAsDataURL(blob);
  //   })

  //   return result
  // };
  hasAthleteHolds(element: Athlete) {
    if (this.haveAtheleteHolds(element.athlete_hold) ||
      this.checkAthleteexecutiveholds(element.athleteexecutiveholds ? element.athleteexecutiveholds : [])) {
      return true;
    } else {
      return false;
    }
  }
  hasCOVID(element: Athlete) {
    if (element.athleteexecutiveholds && element.athleteexecutiveholds.length) {
      return element.athleteexecutiveholds.find(a => a.cleared === false && a.executivehold.id === 5) ? true : false;
    } else {
      return false;
    }
  }
  haveAtheleteHolds(athlete_hold: AthleteHolds | undefined) {
    if (athlete_hold && athlete_hold.isPhotoUploaded && athlete_hold.isPaymentReceived && athlete_hold.isBirthCertificate
      && athlete_hold.isHitcheckCompleted
      && athlete_hold.isContractSigned) {
      return false;
    } else {
      return true;
    }
  }
  checkAthleteexecutiveholds(athleteexecutiveholds: Array<Athleteexecutivehold>) {
    if (athleteexecutiveholds.length > 0) {
      return true;
    } else {
      return false;
    }
  }
}
