import { UserAuthService } from '../services';
import { SubDataService } from '../services/sub-data.service';
import { Appmodule, User, Permission } from './../../models';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpService } from '../services/http.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  user: User;
  userPermissions: Array<Permission>;
  usrapps: Array<any> = [];

  constructor(private httpService: HttpService, private auth: UserAuthService, private router: Router, private subDataService: SubDataService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const userService = new UserAuthService(this.httpService, this.router);
    const currentUser = userService.currentUserValue;
    if (currentUser && currentUser.token) {
      return new Promise(res => {
        this.auth.getLoggedinUserInfo().subscribe((user: any) => {
          this.user = user.data;
          this.usrapps = this.user.uroles;
          if (this.user.uroles && this.user.uroles.length > 0) {
            this.subDataService.setLoggedInUserDataInfo(this.usrapps);
            // ************************************* */
            const urltoMatch = state.url;
            const isUrlMatched = this.usrapps.filter((appmod: Appmodule) => {
              if (appmod.route_link !== '' && urltoMatch.includes(appmod.route_link as string)) {
                return appmod;
              }
              return null;
            });
            if (isUrlMatched.length > 0) {
              res(true);
            } else {
              this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
              res(false);
            }
          }
        });
      });
    }

    // not logged in so redirect to login page with the return url and return false
    this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
    return false;
  }

}
