import { ManageOrganizationService, RoleService } from '../services';
import { VolunteersService } from '../services/volunteers.service';
import { Directive } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, FormGroup } from '@angular/forms';
import { AtheleteService } from '../services/athelete.service';

@Directive({
  selector: '[appCustomValidator]'
})
export class CustomValidatorsDirective {

  constructor() { }
  static vaildEmail(c: FormControl): ValidationErrors {
    const email: string = c.value;
    const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email && email.length > 0 && !reg.test(email)) {
      return { vaildEmail: true };
    }
    return [];
  }
  static vaildEmailTYFAAPPDomain(c: FormControl): ValidationErrors {
    const email: string = c.value?c.value.toLowerCase():"";
    const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email && email.length > 0 && !reg.test(email)) {
      return { vaildEmail: true };
    }
    if (email && email.length > 0 && reg.test(email) && !email.endsWith("@tyfa.app")) {
      return { tyfaAppDomain: true };
    }
    return [];
  }
  static chekcIfSpecialCharsExists(value) {
    let format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/;
    return format.test(value);
  }
  public static checkIfEnteredValueLessThanEqual100(control: FormControl): ValidationErrors {
    if (control.value == null) {
      return [];
    } else if (control.value && /[a-z]/i.test(control.value)) {
      return { enterOnlyNumber: true };
    } else if (CustomValidatorsDirective.chekcIfSpecialCharsExists(control.value)) {
      return { invalidEntry: true };
    }
    else if (control.value.toString().split('.').length > 2) {
      return { invalidEntry: true };
    }
    else if (control.value && isNaN(parseFloat(control.value))) {
      return { itsNotANumber: true };
    }
    else if (control.value && parseFloat(control.value) > 100) {
      return { invalidValue: true };
    } else {
      return [];
    }
  }
  public static checkIfAgeIsLessThanEqual100(control: FormControl): ValidationErrors {
    if (control.value == null) {
      return [];
    } else if (control.value && /[a-z]/i.test(control.value)) {
      return { enterOnlyNumber: true };
    } else if (CustomValidatorsDirective.chekcIfSpecialCharsExists(control.value)) {
      return { invalidEntry: true };
    }
    else if (control.value.toString().split('.').length > 1) {
      return { invalidEntry: true };
    }
    else if (control.value && isNaN(parseInt(control.value))) {
      return { itsNotANumber: true };
    }
    else if (control.value && parseInt(control.value) > 100) {
      return { invalidValue: true };
    } else {
      return [];
    }
  }
  public static checkIfDistanceIsLessThanEqual999(control: FormControl): ValidationErrors {
    if (control.value == null) {
      return [];
    } else if (control.value && /[a-z]/i.test(control.value)) {
      return { enterOnlyNumber: true };
    } else if (CustomValidatorsDirective.chekcIfSpecialCharsExists(control.value)) {
      return { invalidEntry: true };
    }
    else if (control.value.toString().split('.').length > 1) {
      return { invalidEntry: true };
    }
    else if (control.value && isNaN(parseInt(control.value))) {
      return { itsNotANumber: true };
    }
    else if (control.value && parseInt(control.value) > 999) {
      return { invalidValue: true };
    } else {
      return [];
    }
  }
  public static checkIfEnteredValueLessThanEqual150(control: FormControl): ValidationErrors {
    if (control.value == null) {
      return [];
    } else if (control.value && /[a-z]/i.test(control.value)) {
      return { enterOnlyNumber: true };
    } else if (CustomValidatorsDirective.chekcIfSpecialCharsExists(control.value)) {
      return { invalidEntry: true };
    }
    else if (control.value.toString().split('.').length > 2) {
      return { invalidEntry: true };
    }
    else if (control.value && isNaN(parseFloat(control.value))) {
      return { itsNotANumber: true };
    } else if (control.value && parseFloat(control.value) > 150) {
      return { invalidValue: true };
    } else {
      return [];
    }
  }

  static vaildDob(c: FormControl): ValidationErrors {
    const dob: string = c.value;
    if (dob) {
      const dobd: string = dob.replace(/[\W_]/g, '');
      if (dobd.length !== 8) {
        return { validDob: true };
      }
      return [];
    }
    return [];
  }

  static isEmailExist(customService: any, orgEmail?: string) {
    return (control: AbstractControl) => {
      return customService.validateEmail({ email: control.value, oldEmail: orgEmail });
    };
  }

  static isAthprntEmailExist(athleteService: AtheleteService, apEmail?: string) {
    return (control: AbstractControl) => {
      apEmail = (apEmail) ? apEmail : control.value;
      return athleteService.validateEmail({ email: control.value, oldEmail: apEmail });
    };
  }

  static required(control: AbstractControl): any {
    return (control.value.trim() === '') ?
      { 'required': true } :
      null;
  }

  static isvEmailExist(volunteerService: VolunteersService, vEmail?: string) {
    return (control: AbstractControl) => {
      vEmail = (vEmail) ? vEmail : control.value;
      return volunteerService.validateEmail({ email: control.value, oldEmail: vEmail });
    };
  }

  static isOrgExist(organizationService: ManageOrganizationService, oldName?: string) {
    return (control: AbstractControl) => {
      return organizationService.validateOrg({ organizationName: control.value, oldName: oldName });
    };
  }

  static isRoleExist(rolesService: RoleService, oldName?: string, role_id?: number) {
    return (control: AbstractControl) => {
      return rolesService.validateRole({ name: control.value, oldName: oldName, role_id: role_id });
    };
  }
  public static validPassword(control: FormControl): ValidationErrors {
    if (control.value == null) {
      return [];
    } else if (control.value != null) {
      if (control.value.length < 6) {
        return [];
      }
    }
    const hasNumber = /\d/.test(control.value);
    const hasLower = /[a-z]/.test(control.value);
    const hasUpper = /[A-Z]/.test(control.value);
    const valid = hasNumber && hasLower && hasUpper;
    if (!valid) {
      return { validPassword: true };
    }

    return [];
  }
  static checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      const passwordInput = group.controls[passwordKey];
      const passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true });
      } else {
        return passwordConfirmationInput.setErrors(null);
      }
    };
  }
}
