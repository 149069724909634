import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
export const InterceptorSkipHeader = 'X-Skip-Interceptor';
import { timeout, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient) { }

  httpGet(path: string, params?: HttpParams) {
    let param = {};
    if (params) {
      param = params;
    }
    return this.http.get(environment.apiUrl + path, { params: param });
  }

  httpPost(path: string, body: any) {
    const data = JSON.stringify(body);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options = { headers };
    return this.http.post(environment.apiUrl + path, data, options);
  }

  httpPut(path: string, body: any) {
    const data = JSON.stringify(body);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    const options = { headers };
    return this.http.put(environment.apiUrl + path, data, options);
  }

  httpDelete(path: any, data: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.request('delete', environment.apiUrl + path, { body: data, headers });
  }
  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }
  thirdPartyURL(path: string, params?: HttpParams) {
    let param = {};
    if (params) {
      param = params;
    }
    return this.http.get(path, { params: param });
  }
}
