import { environment } from '../../../environments/environment';
import { Organization } from '../../models';
import { MasterService } from '../../shared/services/master.service';
import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ModulePermissions } from 'src/app/models/permission';
import { ModulePermissionsService } from 'src/app/shared/services/module-permissions.service';
import { UserAuthService } from 'src/app/shared/services';
import { HttpService } from 'src/app/shared/services/http.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-export-rosters',
  templateUrl: './export-rosters.component.html',
  styleUrls: ['./export-rosters.component.scss']
})
export class ExportRostersComponent implements OnInit {
  organizations: Array<Organization>;
  selectedOrganization = 0; // Here 0 mean all organization.
  modulePermissions: ModulePermissions;
  constructor(private bottomSheetRef: MatBottomSheetRef<ExportRostersComponent>,
    private modPermServices: ModulePermissionsService,
    private httpService: HttpService, private router: Router,
    private masterService: MasterService) {
  }

  openLink(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }

  ngOnInit() {
    this.getOrganizations();
    this.modPermServices.getUserModPermission('Export Rosters', (mp: ModulePermissions) => {
      this.modulePermissions = mp;
    });
  }
  getOrganizations() {
    this.masterService.getOrgOptions().subscribe((options: any) => {
      this.organizations = options.data;
      this.organizations.splice(0, 0, { id: null, organizationName: 'Select Organizations' });
    });
  }
  downloadAthletes($event: any) {
    $event.preventDefault();
    const currentUser = new UserAuthService(this.httpService, this.router);
    const url = environment.apiUrl + '/athlete/download?x-tyfa-token=' + currentUser.currentUserValue.token + '&organizationId=' + this.selectedOrganization;
    window.open(url, '_self');
  }
  downloadVolunteers($event: any) {
    $event.preventDefault();
    const currentUser = new UserAuthService(this.httpService, this.router);
    const url = environment.apiUrl + '/volunteer/download?x-tyfa-token=' + currentUser.currentUserValue.token + '&organizationId=' + this.selectedOrganization;
    window.open(url, '_self');
  }
}
